<template>
    <div class="container-fluid p-4">
        <div class="row row-cols-1 mb-n4">
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header card-header-divider">Basic Buttons</div>
                    <div class="card-body text-left">
                        <div>
                            <button class="btn btn-primary mr-2 mb-2">Primary</button>
                            <button class="btn btn-secondary mr-2 mb-2">Secondary</button>
                            <button class="btn btn-success mr-2 mb-2">Success</button>
                            <button class="btn btn-danger mr-2 mb-2">Danger</button>
                            <button class="btn btn-warning mr-2 mb-2">Warning</button>
                            <button class="btn btn-info mr-2 mb-2">Info</button>
                            <button class="btn btn-light mr-2 mb-2">Light</button>
                            <button class="btn btn-dark mr-2 mb-2">Dark</button>

                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header card-header-divider">Icon Buttons</div>
                    <div class="card-body text-left">
                        <div>
                            <button class="btn btn-primary mr-2 mb-2">
                                <fa class="icon-push-right" icon="exclamation-circle"/>
                                Button
                            </button>
                            <button class="btn btn-secondary mr-2 mb-2">
                                <fa class="icon-push-right" icon="exclamation-circle"/>
                                Button
                            </button>
                            <button class="btn btn-success mr-2 mb-2">
                                <fa class="icon-push-right" icon="exclamation-circle"/>
                                Button
                            </button>
                            <button class="btn btn-danger mr-2 mb-2">
                                <fa class="icon-push-right" icon="exclamation-circle"/>
                                Button
                            </button>
                            <button class="btn btn-warning mr-2 mb-2">
                                <fa class="icon-push-right" icon="exclamation-circle"/>
                                Button
                            </button>
                            <button class="btn btn-info mr-2 mb-2">
                                <fa class="icon-push-right" icon="exclamation-circle"/>
                                Button
                            </button>
                            <button class="btn btn-light mr-2 mb-2">
                                <fa class="icon-push-right" icon="exclamation-circle"/>
                                Button
                            </button>
                            <button class="btn btn-dark mr-2 mb-2">
                                <fa class="icon-push-right" icon="exclamation-circle"/>
                                Button
                            </button>
                        </div>
                        <div class="mt-3">
                            <button class="btn btn-primary mr-2 mb-2">Button
                                <fa class="icon-push-left" icon="exclamation-circle"/>
                            </button>
                            <button class="btn btn-secondary mr-2 mb-2">Button
                                <fa class="icon-push-left" icon="exclamation-circle"/>
                            </button>
                            <button class="btn btn-success mr-2 mb-2">Button
                                <fa class="icon-push-left" icon="exclamation-circle"/>
                            </button>
                            <button class="btn btn-danger mr-2 mb-2">Button
                                <fa class="icon-push-left" icon="exclamation-circle"/>
                            </button>
                            <button class="btn btn-warning mr-2 mb-2">Button
                                <fa class="icon-push-left" icon="exclamation-circle"/>
                            </button>
                            <button class="btn btn-info mr-2 mb-2">Button
                                <fa class="icon-push-left" icon="exclamation-circle"/>
                            </button>
                            <button class="btn btn-light mr-2 mb-2">Button
                                <fa class="icon-push-left" icon="exclamation-circle"/>
                            </button>
                            <button class="btn btn-dark mr-2 mb-2">Button
                                <fa class="icon-push-left" icon="exclamation-circle"/>
                            </button>
                        </div>
                        <div class="mt-3">
                            <button class="btn btn-primary active mr-2 mb-2">Button
                                <fa class="icon-push-left" icon="exclamation-circle"/>
                            </button>
                            <button class="btn btn-secondary active mr-2 mb-2">Button
                                <fa class="icon-push-left" icon="exclamation-circle"/>
                            </button>
                            <button class="btn btn-success active mr-2 mb-2">Button
                                <fa class="icon-push-left" icon="exclamation-circle"/>
                            </button>
                            <button class="btn btn-danger active mr-2 mb-2">Button
                                <fa class="icon-push-left" icon="exclamation-circle"/>
                            </button>
                            <button class="btn btn-warning active mr-2 mb-2">Button
                                <fa class="icon-push-left" icon="exclamation-circle"/>
                            </button>
                            <button class="btn btn-info active mr-2 mb-2">Button
                                <fa class="icon-push-left" icon="exclamation-circle"/>
                            </button>
                            <button class="btn btn-light active mr-2 mb-2">Button
                                <fa class="icon-push-left" icon="exclamation-circle"/>
                            </button>
                            <button class="btn btn-dark active mr-2 mb-2">Button
                                <fa class="icon-push-left" icon="exclamation-circle"/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header card-header-divider">Button sizing</div>
                    <div class="card-body">
                        <div>
                            <button class="btn btn-primary btn-sm mr-2 mb-2">Small</button>
                            <button class="btn btn-primary mr-2 mb-2">Default</button>
                            <button class="btn btn-primary btn-lg mb-2">Large</button>
                        </div>
                        <div class="mt-4">
                            <button class="btn btn-primary btn-sm mr-2 mb-2">
                                <fa class="icon-push-right" icon="exclamation-circle"/>
                                Small
                            </button>
                            <button class="btn btn-primary mr-2 mb-2">
                                <fa class="icon-push-right" icon="exclamation-circle"/>
                                Default
                            </button>
                            <button class="btn btn-primary btn-lg mb-2">
                                <fa class="icon-push-right" icon="exclamation-circle"/>
                                Large
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card mb-4">
                    <div class="card-header card-header-divider">Buttons / Outline</div>
                    <div class="card-body text-left">
                        <div>
                            <button class="btn btn-outline-primary mr-2 mb-2">Primary</button>
                            <button class="btn btn-outline-secondary mr-2 mb-2">Secondary</button>
                            <button class="btn btn-outline-success mr-2 mb-2">Success</button>
                            <button class="btn btn-outline-danger mr-2 mb-2">Danger</button>
                            <button class="btn btn-outline-warning mr-2 mb-2">Warning</button>
                            <button class="btn btn-outline-info mr-2 mb-2">Info</button>
                            <button class="btn btn-outline-light mr-2 mb-2">Light</button>
                            <button class="btn btn-outline-dark mr-2 mb-2">Dark</button>
                        </div>

                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header card-header-divider">Icon Buttons</div>
                    <div class="card-body text-left">
                        <div>
                            <button class="btn btn-outline-primary mr-2 mb-2">
                                <fa class="icon-push-right" icon="exclamation-circle"/>
                                Button
                            </button>
                            <button class="btn btn-outline-secondary mr-2 mb-2">
                                <fa class="icon-push-right" icon="exclamation-circle"/>
                                Button
                            </button>
                            <button class="btn btn-outline-success mr-2 mb-2">
                                <fa class="icon-push-right" icon="exclamation-circle"/>
                                Button
                            </button>
                            <button class="btn btn-outline-danger mr-2 mb-2">
                                <fa class="icon-push-right" icon="exclamation-circle"/>
                                Button
                            </button>
                            <button class="btn btn-outline-warning mr-2 mb-2">
                                <fa class="icon-push-right" icon="exclamation-circle"/>
                                Button
                            </button>
                            <button class="btn btn-outline-info mr-2 mb-2">
                                <fa class="icon-push-right" icon="exclamation-circle"/>
                                Button
                            </button>
                            <button class="btn btn-outline-dark mr-2 mb-2">
                                <fa class="icon-push-right" icon="exclamation-circle"/>
                                Button
                            </button>
                        </div>
                        <div class="mt-3">
                            <button class="btn btn-outline-primary mr-2 mb-2">Button
                                <fa class="icon-push-left" icon="exclamation-circle"/>
                            </button>
                            <button class="btn btn-outline-secondary mr-2 mb-2">Button
                                <fa class="icon-push-left" icon="exclamation-circle"/>
                            </button>
                            <button class="btn btn-outline-success mr-2 mb-2">Button
                                <fa class="icon-push-left" icon="exclamation-circle"/>
                            </button>
                            <button class="btn btn-outline-danger mr-2 mb-2">Button
                                <fa class="icon-push-left" icon="exclamation-circle"/>
                            </button>
                            <button class="btn btn-outline-warning mr-2 mb-2">Button
                                <fa class="icon-push-left" icon="exclamation-circle"/>
                            </button>
                            <button class="btn btn-outline-info mr-2 mb-2">Button
                                <fa class="icon-push-left" icon="exclamation-circle"/>
                            </button>
                            <button class="btn btn-outline-dark mr-2 mb-2">Button
                                <fa class="icon-push-left" icon="exclamation-circle"/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header card-header-divider">Buttons Size</div>
                    <div class="card-body">
                        <div>
                            <button class="btn btn-outline-primary btn-sm mr-2 mb-2">Button</button>
                            <button class="btn btn-outline-primary mr-2 mb-2">Button</button>
                            <button class="btn btn-outline-primary btn-lg mb-2">Button</button>
                        </div>
                        <div class="mt-4">
                            <button class="btn btn-outline-primary btn-sm mr-2 mb-2">
                                <fa class="icon-push-right" icon="exclamation-circle"/>
                                Button
                            </button>
                            <button class="btn btn-outline-primary mr-2 mb-2">
                                <fa class="icon-push-right" icon="exclamation-circle"/>
                                Button
                            </button>
                            <button class="btn btn-outline-primary btn-lg mb-2">
                                <fa class="icon-push-right" icon="exclamation-circle"/>
                                Button
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>

export default {
    name: 'Buttons',
}
</script>
